import { Account } from "models/account"
import { GeoData } from "models/workstream"
import request from "pmsa-polaris/api/request"

export type AccountResponse = Readonly<Account>

const geosApi = {
  get: async () => request.get<Readonly<GeoData>>(`/geodata`),
}

export default geosApi
