import { useCollection } from "@amzn/awsui-collection-hooks"
import { Container, Grid } from "@amzn/awsui-components-react"
import CollectionPreferences, {
  CollectionPreferencesProps,
} from "@amzn/awsui-components-react/polaris/collection-preferences"
import Header from "@amzn/awsui-components-react/polaris/header"
import Pagination from "@amzn/awsui-components-react/polaris/pagination"
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between"
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter"
import RecommendationCard from "components/Cards/RecommendationCard"
import { Recommendation } from "models/recommendation"
import usePersistentState from "pmsa-polaris/hooks/usePersistentState"
import { getMatchesCountText, paginationLabels } from "pmsa-polaris/utils"
import React, { useEffect, useState } from "react"

const SEARCHABLE_COLUMNS = [
  "partnerName",
  "title",
  "benefits",
  "stageTransition",
]
type Props = {
  data: Recommendation[]
  error: any
  loading: boolean
}
const PAGE_SIZE_OPTIONS = [
  { value: 5, label: "5 Recommendations" },
  { value: 10, label: "10 Recommendations" },
  { value: 20, label: "20 Recommendations" },
]

const RecommendationsList: React.FC<Props> = ({ data, error, loading }) => {
  const [errorMessage, setErrorMessage] = useState(
    "Failed to load recommendations"
  )

  useEffect(() => {
    if (error && error.response?.data?.errorCode == "USER_NOT_FOUND") {
      error.response?.data?.message &&
        setErrorMessage(error.response.data.message)
    }
  }, [error])

  const [preferences, setPreferences] =
    usePersistentState<CollectionPreferencesProps.Preferences>(
      "pma.recommendationPreferences",
      {
        pageSize: 5,
        wrapLines: false,
      }
    )

  const clearFilter = () => {
    actions.setFiltering("")
  }

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(data || [], {
    filtering: {
      filteringFunction: (item, filteringText) => {
        const filteringTextLowerCase = filteringText.toLowerCase()
        return SEARCHABLE_COLUMNS.map(
          (key) => item[key as keyof Recommendation]
        ).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().indexOf(filteringTextLowerCase) > -1
        )
      },
    },
    pagination: {
      pageSize: preferences.pageSize,
    },
  })

  return (
    <SpaceBetween size="l">
      <Container
        disableContentPaddings={true}
        header={
          <SpaceBetween size="xs">
            <TableHeader itemCount={data?.length} />
            <Grid
              gridDefinition={[{ colspan: 8 }, { colspan: 3 }, { colspan: 1 }]}
            >
              <TextFilter
                {...filterProps}
                // countText={getMatchesCountText(filteredItemsCount!)}
                filteringAriaLabel="Filter recommendations"
                filteringPlaceholder="Find Recommendations"
              />
              <Pagination {...paginationProps} ariaLabels={paginationLabels} />
              <CollectionPreferences
                title="Preferences"
                confirmLabel="Confirm"
                cancelLabel="Cancel"
                preferences={preferences}
                onConfirm={({ detail }) => setPreferences(detail)}
                pageSizePreference={{
                  title: "Page size",
                  options: PAGE_SIZE_OPTIONS,
                }}
              />
            </Grid>
            {filterProps.filteringText && (
              <span className="filtering-results">
                {getMatchesCountText(filteredItemsCount!)}
              </span>
            )}
          </SpaceBetween>
        }
      ></Container>

      {items.map((item, i) => (
        <RecommendationCard recommendation={item} key={i} />
      ))}
    </SpaceBetween>

    // <Cards
    // cardDefinition={undefined}
    // {...collectionProps}
    // loading={loading}
    // loadingText="Loading recommendations"
    // selectionType="multi"
    // stickyHeader={true}
    // header={<TableHeader
    //   itemCount={data?.length}
    //   selectedItems={selectedItems} />}
    // items={items}
    // pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
    // filter={<SpaceBetween size="l">
    //   <ColumnLayout columns={2}>
    //     <TextFilter
    //       {...filterProps}
    //       // countText={getMatchesCountText(filteredItemsCount!)}
    //       filteringAriaLabel="Filter opportunities"
    //       filteringPlaceholder="Find Partner" />
    //     {filterProps.filteringText && (
    //       <span className="filtering-results">
    //         {getMatchesCountText(filteredItemsCount!)}
    //       </span>
    //     )}
    //   </ColumnLayout>
    // </SpaceBetween>}
    // preferences={
    //   <CollectionPreferences
    //     title="Preferences"
    //     confirmLabel="Confirm"
    //     cancelLabel="Cancel"
    //     preferences={preferences}
    //     onConfirm={({ detail }) => setPreferences(detail)}
    //     pageSizePreference={{
    //       title: "Page size",
    //       options: PAGE_SIZE_OPTIONS,
    //     }}
    //     wrapLinesPreference={{
    //       label: "Wrap lines",
    //       description: "Check to see all the text and wrap the lines",
    //     }}
    //     visibleContentPreference={{
    //       title: "Select visible columns",
    //       options: VISIBLE_CONTENT_OPTIONS,
    //     }}
    //   />
    // }
    // />
  )
}

const TableHeader: React.FC<{
  itemCount?: number
  selectedItems?: Readonly<Recommendation[]>
}> = ({ itemCount, selectedItems }) => {
  return (
    <Header
      variant="h2"
      counter={
        (itemCount &&
          (selectedItems
            ? `(${selectedItems.length}/${itemCount})`
            : `(${itemCount})`)) ||
        ""
      }
    >
      Recommendations
    </Header>
  )
}

export default RecommendationsList
