import { Dimension } from "models/dimension"
import { GTM } from "models/gtm"
import { Partner } from "models/partner"
import { Recommendation } from "models/recommendation"
import request from "pmsa-polaris/api/request"

export type PartnerRequest = Omit<
  Partner,
  "lastModifiedDate" | "createdDate" | "id" | "name"
>

export type PartnerResponse = Readonly<Partner>
export type RecommendationResponse = Readonly<Recommendation>
export type DimensionResponse = Readonly<Dimension>
export type GTMResponse = Readonly<GTM>

const PartnersApi = {
  getAll: (expand?: boolean, alias?: string) =>
    request.get<PartnerResponse[]>("/partners", {
      params: typeof expand === "boolean" && {
        expand,
        alias,
      },
    }),

  get: (id: string) =>
    request.get<PartnerResponse>(`/partners/${id}`),

  create: (params: PartnerRequest) =>
    request.post<PartnerResponse>("/partners", params),

  update: (id: string, params: PartnerRequest) =>
    request.put<PartnerResponse>(`/partners/${id}`, params),

  getDimensions: (id: string) =>
    request.get<DimensionResponse[]>(
      `/partners/${id}/dimensions`
    ),

  getRecommendations: (id: string) =>
    request.get<RecommendationResponse[]>(
      `/partners/${id}/recommendations`
    ),

  getGTM: (id: string, dimension: string) =>
    request.get<GTMResponse[]>(
      `/partners/${id}/${dimension}/gtm`
    ),
}

export default PartnersApi
