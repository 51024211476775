import { Grid } from "@amzn/awsui-components-react"
import Container from "@amzn/awsui-components-react/polaris/container"
import Header from "@amzn/awsui-components-react/polaris/header"
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between"
import { GTM } from "models/gtm"
import NavigationButton from "pmsa-polaris/components/NavigationButton"

type Props = {
  gtm: GTM
  dimension: string
}

const RecommendationCard: React.FC<Props> = ({ gtm, dimension, ...props }) => {
  return (
    <div>
      <Container
        header={
          <Header
            variant="h3"
            actions={
              <SpaceBetween direction="horizontal" size="m">
                <NavigationButton variant="normal" href="#">
                  Add Activity
                </NavigationButton>
              </SpaceBetween>
            }
          >
            {gtm.title}
          </Header>
        }
      >
        <p>
          {" "}
          <strong> Description: </strong> {gtm.description}
        </p>
        <p>
          {" "}
          <strong> Benefits: </strong> {gtm.benefits}
        </p>
        <p>
          {" "}
          <strong> Action: </strong>
          {gtm.action}
        </p>
        <Grid gridDefinition={[{ colspan: 3, offset: { s: 9 } }]}>
          <strong>
            {" "}
            Eligible for partners with {dimension} Maturity &gt; {gtm.maturity}
          </strong>
        </Grid>
      </Container>
    </div>
  )
}

export default RecommendationCard
