import { ExpandableSection, Table } from "@amzn/awsui-components-react"
import Container from "@amzn/awsui-components-react/polaris/container"
import Header from "@amzn/awsui-components-react/polaris/header"
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between"
import { Recommendation } from "models/recommendation"
import NavigationButton from "pmsa-polaris/components/NavigationButton"

import { COLUMN_DEFINITIONS } from "./RecommendationTableConfig"

type Props = {
  recommendation: Recommendation
}

const RecommendationCard: React.FC<Props> = ({ recommendation, ...props }) => {
  return (
    <div>
      <Container
        header={
          <Header
            variant="h3"
            actions={
              <SpaceBetween direction="horizontal" size="m">
                <NavigationButton variant="normal" href="#">
                  Add Activity
                </NavigationButton>
              </SpaceBetween>
            }
          >
            {recommendation.title}
          </Header>
        }
      >
        <p>
          {" "}
          <strong> Capability: </strong> {recommendation.capability.name}
        </p>
        <p>
          {" "}
          <strong> Stage Transition: </strong> {recommendation.stageTransition}
        </p>
        <p>
          {" "}
          <strong> Benefit: </strong>
          {recommendation.benefits}
        </p>
      </Container>

      <Container
        disableContentPaddings={true}
        header={
          <ExpandableSection
            header={<strong>Resources</strong>}
            variant="footer"
            disableContentPaddings={true}
          >
            <Table
              columnDefinitions={COLUMN_DEFINITIONS}
              items={recommendation.resources}
              stickyHeader={true}
              resizableColumns={true}
              sortingDisabled={true}
            />
          </ExpandableSection>
        }
      ></Container>
    </div>
  )
}

export default RecommendationCard
