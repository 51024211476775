import { Box, Button, ColumnLayout, Container, Form, FormField, Header, Input, Link, RadioGroup, SpaceBetween, Textarea, Wizard } from "@amzn/awsui-components-react"
import assessments, { AssessmentRequest } from "api/assessments"
import { AxiosError } from "axios"
import { Formik, FormikHelpers, FormikProps } from "formik"
import { Questionare } from "models/assessment"
import { DIMENSIONS } from "models/dimension"
import moment from "moment"
import LoadingHeader from "pmsa-polaris/components/LoadingHeader"
import NavigationButton from "pmsa-polaris/components/NavigationButton"
import { useAppContext } from "pmsa-polaris/context/AppContext"
import useFlashbar from "pmsa-polaris/hooks/useFlashbar"
import usePromise from "pmsa-polaris/hooks/usePromise"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { routeParams } from "routes"
import * as Yup from "yup"


type AssessmentFormValues = {
  assessment: AssessmentRequest
}

const DEFAULT_FORM_VALUES = {
  assessment: {
    assessmentId:"",
    dimension: DIMENSIONS[0],
    questionaire: [] as Questionare[],
    score:0,
    lastModifiedData: moment().format("YYYY-MM-DD"),
    createdDate: moment().format("YYYY-MM-DD"),
  }
}

// const getValidationSchema = () => {
//   return Yup.object().shape({
//     partner: Yup.object().shape({
//       partnerName: Yup.string().required("You must specify a partner name"),
//       pmsa: Yup.string().required("You must specify a PMSA alias "),
//       pdm: Yup.string().required("You must specify a PDM alias  "),
//       spmsId: Yup.string().required("You must specify an SpmsId "),
//       geos: Yup.string().required("You must specify geography."),
//     }),
//   })
// }

const FormContent : React.FC=() => {
  const [value, setValue] = useState("");

  const { id, dimension } = useParams<{
    id?: string
    dimension?: string
  }>()

  const [initialValues, setInitialValues] = useState<AssessmentFormValues>({
    assessment: {
      ...DEFAULT_FORM_VALUES.assessment
    },
  })
  const [
    activeStepIndex,
    setActiveStepIndex
  ] = useState(0);

  const navigate = useNavigate()
  const {setContext} = useAppContext()

  const [ 
    {
      loading: createAssessmentLoading,
      data: createdAssessment,
      error: createAssessmentError,
    },
    createAssement
  ] = usePromise(assessments.create)

  const [
    {
      loading: updateAssessmentLoading,
      data: updatedAssessment,
      error: updateAssessmentError,
    },
    updateAssessment
  ] = usePromise(assessments.update)

  const [ 
    {
      loading: getAssessmentLoading,
      data: existingAssessment,
      error: getAssessmentError,
    },
    getAssessment,
  ] = usePromise(assessments.get)

  const isLoading = 
    createAssessmentLoading || updateAssessmentLoading || getAssessmentLoading

  const setFlashMessages = useFlashbar()

  // const handleInfoClicked =
  //   (toolsTopic: keyof typeof topics) =>
  //   (e: CustomEvent<BaseNavigationDetail>) => {
  //     e.preventDefault()
  //     setContext({ toolsOpen: true, toolsTopic })
  //   }
  useEffect(() => {
    if (id) {
      const fetchAssessment = async () => {
        const assessment = await getAssessment(id,"dimension")
        const values: AssessmentFormValues = {
          assessment: {
            ...assessment,
          },
        }
        setInitialValues(values)
      }
      fetchAssessment()
    }
  }, [getAssessment, id])

  const handleSubmit = useCallback(
    async (
      { assessment: { ...assessment } }: AssessmentFormValues,
      { setSubmitting }: FormikHelpers<AssessmentFormValues>
    ) => {
      try {
        if (assessment) {
          const assessmentRequest: AssessmentRequest = {
            ...assessment,
          }
          if (id) {
            const updatedAssessment = await updateAssessment(id, assessmentRequest)
            setFlashMessages([
              {
                content: "Assessment updated successfully",
                presist: true,
                type: "success",
              },
            ])
            navigate(routeParams.partnerDetails({ id }))
          } else {
            const newAssessment = await createAssement(assessmentRequest)
            setFlashMessages([
              {
                content: "Partner assessed successfully",
                presist: true,
                type: "success",
              },
            ])
            navigate(routeParams.partnerDetails({ id: newAssessment.id }))
          }
        }
      } catch (error) {
        const err = error as AxiosError<{ statusCode: number; message: string }>
        setFlashMessages([
          {
            type: "error",
            content: "There was an issue with creating assessment.",
          },
        ])
      } finally {
        setSubmitting(false)
      }
    },
    [createAssement, navigate, id, setFlashMessages, updateAssessment]
  )
  // const validationSchema = useMemo(() => getValidationSchema(), [])
  const pageTitle = id ? "Update Assessment" : "Add Assessment"
  return (
    <Formik
    initialValues={initialValues}
    enableReinitialize
    onSubmit={handleSubmit}
    validateOnChange={false}
    >
{({
        handleSubmit,
        errors,
        values: { assessment },
        isSubmitting,
        setFieldValue,
      }: FormikProps<AssessmentFormValues>) => {
        return (
          <form
            onSubmit={handleSubmit}
            className={isLoading ? "loading" : undefined}
          >
               <Wizard
      i18nStrings={{
        stepNumberLabel: stepNumber =>
          `Capability ${stepNumber}`,
        collapsedStepsLabel: (stepNumber, stepsCount) =>
          `Capability ${stepNumber} of ${stepsCount}`,
        cancelButton: "Save and Exit",
        previousButton: "Previous",
        nextButton: "Next",
        submitButton: "Submit Assessment",
        optional: "optional"
      }}
      onNavigate={({ detail }) =>
        setActiveStepIndex(detail.requestedStepIndex)
      }
      activeStepIndex={activeStepIndex}
      // allowSkipTo
      steps={
        [
        {
          title: "ECS/Fargate Delivery",
          info: <Link variant="info">Info</Link>,
          content: (
            <Container
              header={
                <Header variant="h2">
                  How knowledgeable the partner is with building, deploying, operating and updating applications running in AWS ECS and Fargate for ECS?
                </Header>
              }
              footer={
                <div>
                <Header variant="h2">Notes</Header>
                <Textarea
                  onChange={({ detail }) => setValue(detail.value)}
                  value={value}
                  placeholder="This is a placeholder"
                />
                </div>
              }
            >
              <SpaceBetween direction="vertical" size="l">
              <RadioGroup
      value={value}
      items={[
        { value: "1", label: <div><strong>Initial</strong> - The partner is net new to ECS/Fargate and has only a rudimentary understanding of the technologies and services</div> },
        { value: "2", label: <div><strong>Piloting</strong> - The partner has built a few example workloads leveraging ECS/Fargate</div> },
        { value: "3", label: <div><strong>Adopting</strong> - They organization has developed a single, well-defined offering that leverages ECS/Fargate</div> },
        { value: "4", label: <div><strong>Maturing</strong> - The partner has built out multiple offerings related to ECS/Fargate and have a basic library of implementation patterns that cover the core lifecycle of containerized applications running on ECS/Fargate</div> },
        { value: "5", label: <div><strong>Innovating</strong> - The partner has experience implementing complex solutions leveraging ECS/Fargate.</div> },
        { value: "NA", label: <div><strong>NA</strong> - Select this option if the partner is not interested in or feels that this capability does not relate to them.  By selecting this option, you are expected to provide comments below explaining this decision. THIS OPTION WILL HAVE AN IMPACT ON THE EVALUATION OF THE PARTNER</div> }
      ]}
    />
              </SpaceBetween>
            </Container>
          )
        },
        {
          title: "EKS/Kubernetes Delivery",
          info: <Link variant="info">Info</Link>,
          content: (
            <Container
              header={
                <Header variant="h2">
                  How knowledgeable the partner is with building, deploying, operating and updating applications running in AWS EKS and Kubernetes for AWS?
                </Header>
              }
              footer={
                <div>
                <Header variant="h2">Notes</Header>
                <Textarea
                  onChange={({ detail }) => setValue(detail.value)}
                  value={value}
                  placeholder="This is a placeholder"
                />
                </div>
              }
            >
              <SpaceBetween direction="vertical" size="l">
              <RadioGroup
      value={value}
      items={[
        { value: "1", label: <div><strong>Initial</strong> - The partner is net new to ECS/Fargate and has only a rudimentary understanding of the technologies and services</div> },
        { value: "2", label: <div><strong>Piloting</strong> - The partner has built a few example workloads leveraging ECS/Fargate</div> },
        { value: "3", label: <div><strong>Adopting</strong> - They organization has developed a single, well-defined offering that leverages ECS/Fargate</div> },
        { value: "4", label: <div><strong>Maturing</strong> - The partner has built out multiple offerings related to ECS/Fargate and have a basic library of implementation patterns that cover the core lifecycle of containerized applications running on ECS/Fargate</div> },
        { value: "5", label: <div><strong>Innovating</strong> - The partner has experience implementing complex solutions leveraging ECS/Fargate.</div> },
        { value: "NA", label: <div><strong>NA</strong> - Select this option if the partner is not interested in or feels that this capability does not relate to them.  By selecting this option, you are expected to provide comments below explaining this decision. THIS OPTION WILL HAVE AN IMPACT ON THE EVALUATION OF THE PARTNER</div> }
      ]}
    />
              </SpaceBetween>
            </Container>
          )
        },
        {
          title: "Container Training",
          info: <Link variant="info">Info</Link>,
          content: (
            <Container
              header={
                <Header variant="h2">
                  How knowledgeable the partner is with building, deploying, operating and updating applications running in AWS EKS and Kubernetes for AWS?
                </Header>
              }
              footer={
                <div>
                <Header variant="h2">Notes</Header>
                <Textarea
                  onChange={({ detail }) => setValue(detail.value)}
                  value={value}
                  placeholder="This is a placeholder"
                />
                </div>
              }
            >
              <SpaceBetween direction="vertical" size="l">
              <RadioGroup
      value={value}
      items={[
        { value: "1", label: <div><strong>Initial</strong> - The partner is net new to ECS/Fargate and has only a rudimentary understanding of the technologies and services</div> },
        { value: "2", label: <div><strong>Piloting</strong> - The partner has built a few example workloads leveraging ECS/Fargate</div> },
        { value: "3", label: <div><strong>Adopting</strong> - They organization has developed a single, well-defined offering that leverages ECS/Fargate</div> },
        { value: "4", label: <div><strong>Maturing</strong> - The partner has built out multiple offerings related to ECS/Fargate and have a basic library of implementation patterns that cover the core lifecycle of containerized applications running on ECS/Fargate</div> },
        { value: "5", label: <div><strong>Innovating</strong> - The partner has experience implementing complex solutions leveraging ECS/Fargate.</div> },
        { value: "NA", label: <div><strong>NA</strong> - Select this option if the partner is not interested in or feels that this capability does not relate to them.  By selecting this option, you are expected to provide comments below explaining this decision. THIS OPTION WILL HAVE AN IMPACT ON THE EVALUATION OF THE PARTNER</div> }
      ]}
    />
              </SpaceBetween>
            </Container>
          )
        },
        {
          title: "Container Patterns",
          info: <Link variant="info">Info</Link>,
          content: (
            <Container
              header={
                <Header variant="h2">
                  How knowledgeable the partner is with building, deploying, operating and updating applications running in AWS EKS and Kubernetes for AWS?
                </Header>
              }
              footer={
                <div>
                <Header variant="h2">Notes</Header>
                <Textarea
                  onChange={({ detail }) => setValue(detail.value)}
                  value={value}
                  placeholder="This is a placeholder"
                />
                </div>
              }
            >
              <SpaceBetween direction="vertical" size="l">
              <RadioGroup
      value={value}
      items={[
        { value: "1", label: <div><strong>Initial</strong> - The partner is net new to ECS/Fargate and has only a rudimentary understanding of the technologies and services</div> },
        { value: "2", label: <div><strong>Piloting</strong> - The partner has built a few example workloads leveraging ECS/Fargate</div> },
        { value: "3", label: <div><strong>Adopting</strong> - They organization has developed a single, well-defined offering that leverages ECS/Fargate</div> },
        { value: "4", label: <div><strong>Maturing</strong> - The partner has built out multiple offerings related to ECS/Fargate and have a basic library of implementation patterns that cover the core lifecycle of containerized applications running on ECS/Fargate</div> },
        { value: "5", label: <div><strong>Innovating</strong> - The partner has experience implementing complex solutions leveraging ECS/Fargate.</div> },
        { value: "NA", label: <div><strong>NA</strong> - Select this option if the partner is not interested in or feels that this capability does not relate to them.  By selecting this option, you are expected to provide comments below explaining this decision. THIS OPTION WILL HAVE AN IMPACT ON THE EVALUATION OF THE PARTNER</div> }
      ]}
    />
              </SpaceBetween>
            </Container>
          )
        },
      ]
    }
    />
          </form>
        )
      }}

    </Formik>
  )
}
export default FormContent;