import partners from "api/partners"
import MainLayout from "pmsa-polaris/components/MainLayout"
import usePromise from "pmsa-polaris/hooks/usePromise"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { Dimension, DIMENSIONS } from "models/dimension"


import FormContent from "./FormContent"
import Spinner from "@amzn/awsui-components-react/polaris/spinner"
// import toolsTopics from "./tools-topics"

const CreateUpdateAssessment = () => {
  const { id: idParam, dimension: dim } = useParams()
  const id = idParam!
  const dimension = dim! 
  // TODO: Remove this ugly code once we have dimension select
  const invalidDimensionError = !!DIMENSIONS.toString().split(",").includes(dimension)
  const [{ data: partner }, getPartner] = usePromise(partners.get)
  useEffect(() => {
    getPartner(id)
  }, [getPartner, id])

  return (
    // TODO: remove this check once a select dimension page is created
  (invalidDimensionError && (
  <MainLayout 
    breadcrumbItems={[
            { text: "Partners", href: "/" },
            {
              text: partner ? partner.partnerName : id,
              href: `/partners/${id}`,
            },
            { text: dimension, href: "" },
            { text:"Assessment", href:""}
    ]}
    // contentType="wizard"
    maxContentWidth={1500}
    // toolsTopics={toolsTopics}
  >
    <FormContent  />
  </MainLayout >
  )||((
    <div className="fullscreen-center">
      <Spinner size="large" />
    </div>
  )) )
)}

export default CreateUpdateAssessment