import HelpPanel from "@amzn/awsui-components-react/polaris/help-panel"

const ToolsPanel = () => (
  <HelpPanel
    header={<h2>Recommendations</h2>}
    footer={
      <p>
        This page displays a list of all of your Recommendations for a partner.
      </p>
    }
  />
)

export default ToolsPanel
