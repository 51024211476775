import { TableProps } from "@amzn/awsui-components-react/polaris/table"
import { Resources } from "models/recommendation"
import NavigationLink from "pmsa-polaris/components/NavigationLink"

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<Resources>[] = [
  {
    id: "name",
    cell: (item) => (
      <NavigationLink href={item.href}>{item.name}</NavigationLink>
    ),
    header: "Resource",
    minWidth: "300px",
    sortingField: "name",
  },
  {
    id: "type",
    header: "Type",
    cell: (item) => item.type,
    // minWidth: "100px",
    sortingField: "type",
  },
  {
    id: "audience",
    header: "Audience",
    cell: (item) => item.audience,
    sortingField: "audience",
  },
]
