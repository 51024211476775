import {
  Button,
  Container,
  Form,
  FormField,
  Header,
  Link,
  Multiselect,
  SpaceBetween,
} from "@amzn/awsui-components-react"
import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces"
import { PartnerRequest } from "api/partners"
import partners from "api/partners"
import { AxiosError } from "axios"
import { Formik, FormikHelpers, FormikProps } from "formik"
import moment from "moment"
import ErrorFocus from "pmsa-polaris/components/ErrorFocus"
import { InputFormField } from "pmsa-polaris/components/FormFields"
import LoadingHeader from "pmsa-polaris/components/LoadingHeader"
import NavigationButton from "pmsa-polaris/components/NavigationButton"
import { useAppContext } from "pmsa-polaris/context/AppContext"
import useFlashbar from "pmsa-polaris/hooks/useFlashbar"
import usePromise from "pmsa-polaris/hooks/usePromise"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import routes, { routeParams } from "routes"
// import {
//   DEFAULT_TASK,
//   getValidaionSchema as getTaskValidationSchema,
// } from "pages/CreateUpdateTask/FormContent"
import * as Yup from "yup"
import { BaseNavigationDetail } from "@amzn/awsui-components-react/polaris/internal/events"
import topics from "./tools-topics"

// import topics from "./tools-topics"

// const { sfdcBaseUrl } = (window as any)["runConfig"]

// type TaskFormValues = Omit<OmitWorkstreamId<TaskRequest>, "milestone"> & {
//   milestoneId: string
// }

type PartnerFormValues = {
  partner: PartnerRequest
}

const DEFAULT_FORM_VALUES = {
  partner: {
    partnerName: "",
    pmsa: "",
    dimensions: [],
    pdm: "",
    spmsId: "",
    geos: "",
    closeDate: moment().add(3, "months").format("YYYY-MM-DD"),
    lastModifiedData: moment().format("YYYY-MM-DD"),
    createdDate: moment().format("YYYY-MM-DD"),
    // accountName:"",
    // accountId:"",
  },
}

// const WORKSTREAM_TYPE = {
//   competency: "Partner Competency",
//   ambassador: "APN Ambassador Program",
// } as const

// const stageOptions = toOptions(...PARTNER_STAGE)

// const containsRegex = (value: string) => /^https:\/\//i.test(value)

const getValidationSchema = () => {
  return Yup.object().shape({
    partner: Yup.object().shape({
      partnerName: Yup.string().required("You must specify a partner name"),
      pmsa: Yup.string().required("You must specify a PMSA alias "),
      pdm: Yup.string().required("You must specify a PDM alias  "),
      spmsId: Yup.string().required("You must specify an SpmsId "),
      geos: Yup.string().required("You must specify geography."),
    }),
  })
}
const FormContent: React.FC = () => {
  const { id } = useParams<{
    id?: string
  }>()

  const [selectedOptions, setSelectedOptions] = useState<OptionDefinition[]>([])

  const [initialValues, setInitialValues] = useState<PartnerFormValues>({
    partner: {
      ...DEFAULT_FORM_VALUES.partner,
    },
  })

  const navigate = useNavigate()
  const { setContext } = useAppContext()

  const [
    {
      loading: createPartnerLoading,
      data: CreatedPartner,
      error: createPartnerError,
    },
    createPartner,
  ] = usePromise(partners.create)

  const [
    {
      loading: updatePartnerLoading,
      data: updatedPartner,
      error: updatePartnerError,
    },
    updatePartner,
  ] = usePromise(partners.update)

  const [
    {
      loading: getPartnerLoading,
      data: existingPartner,
      error: getPartnerError,
    },
    getPartner,
  ] = usePromise(partners.get)

  const isLoading =
    createPartnerLoading || updatePartnerLoading || getPartnerLoading

  const setFlashMessages = useFlashbar()

  const handleInfoClicked =
    (toolsTopic: keyof typeof topics) =>
    (e: CustomEvent<BaseNavigationDetail>) => {
      e.preventDefault()
      setContext({ toolsOpen: true, toolsTopic })
    }
  useEffect(() => {
    if (id) {
      const fetchPartner = async () => {
        const partner = await getPartner(id)
        const values: PartnerFormValues = {
          partner: {
            ...partner,
          },
        }
        const geos = values.partner.geos.split(",")
        const geosOptArr = geos.map((el) => {
          return { value: el, label: el }
        })
        setSelectedOptions(geosOptArr as OptionDefinition[])
        setInitialValues(values)
      }
      fetchPartner()
    }
  }, [getPartner, id])

  const handleSubmit = useCallback(
    async (
      { partner: { ...partner } }: PartnerFormValues,
      { setSubmitting }: FormikHelpers<PartnerFormValues>
    ) => {
      console.log("here")
      try {
        if (partner) {
          const partnerRequest: PartnerRequest = {
            ...partner,
          }
          if (id) {
            const updatedPartner = await updatePartner(id, partnerRequest)
            setFlashMessages([
              {
                content: "Partner updated successfully",
                presist: true,
                type: "success",
              },
            ])
            navigate(routeParams.partnerDetails({ id }))
          } else {
            const newPartner = await createPartner(partnerRequest)
            setFlashMessages([
              {
                content: "Partner created successfully",
                presist: true,
                type: "success",
              },
            ])
            navigate(routeParams.partnerDetails({ id: newPartner.id }))
          }
        }
      } catch (error) {
        const err = error as AxiosError<{ statusCode: number; message: string }>
        setFlashMessages([
          {
            type: "error",
            content: "There was an issue with creating partner.",
          },
        ])
      } finally {
        setSubmitting(false)
      }
    },
    [createPartner, navigate, id, setFlashMessages, updatePartner]
  )
  const validationSchema = useMemo(() => getValidationSchema(), [])
  const pageTitle = id ? "Update Partner" : "Add Partner"
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {({
        handleSubmit,
        errors,
        values: { partner },
        isSubmitting,
        setFieldValue,
      }: FormikProps<PartnerFormValues>) => {
        const getFiscalYear = (closeDateString: string) =>
          parseInt(
            new Date(closeDateString).getFullYear().toString().substring(2)
          )

        const getFiscalYearString = (closeDateString: string) =>
          `FY${getFiscalYear(closeDateString)}`

        return (
          <form
            onSubmit={handleSubmit}
            className={isLoading ? "loading" : undefined}
          >
            <Form
              header={
                <LoadingHeader loading={isLoading}>{pageTitle}</LoadingHeader>
              }
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <NavigationButton
                    variant="link"
                    href={
                      (id && routeParams.partnerDetails({ id })) || routes.home
                    }
                    disabled={isSubmitting || isLoading}
                  >
                    Cancel
                  </NavigationButton>
                  <Button
                    variant="primary"
                    formAction="submit"
                    disabled={isSubmitting || isLoading}
                    loading={isSubmitting || isLoading}
                  >
                    {pageTitle}
                  </Button>
                </SpaceBetween>
              }
              errorText={
                updatePartnerError || createPartnerError || getPartnerError
                  ? "We can't process the request right now because of an issue with the server. Try again later.  If the problem persists, contact support"
                  : ""
              }
            >
              <ErrorFocus />
              <SpaceBetween size="l">
                <Container
                  header={
                    <Header
                      info={
                        <Link
                          variant="info"
                          onFollow={handleInfoClicked("main")}
                        >
                          Info
                        </Link>
                      }
                    >
                      Partner Details
                    </Header>
                  }
                >
                  <SpaceBetween size="l">
                    {/* <FormField
                      label="Partner Name"
                      description="Partner name"
                    >
                      <TextContent>
                        <strong>{partner.partnerName}</strong>
                      </TextContent>
                    </FormField> */}
                    <InputFormField
                      name="partner.partnerName"
                      inputMode="text"
                      label="Partner Name"
                      placeholder="Placeholder text"
                      required
                    />
                    <InputFormField
                      name="partner.spmsId"
                      inputMode="text"
                      label="SPMS ID"
                      placeholder="Placeholder text"
                      required
                    />
                    <InputFormField
                      name="partner.pdm"
                      inputMode="text"
                      label="PDM"
                      placeholder="Placeholder text"
                      required
                    />
                    <InputFormField
                      name="partner.pmsa"
                      inputMode="text"
                      label="PMSA"
                      placeholder="Placeholder text"
                      required
                    />
                    <FormField label="Geos">
                      <Multiselect
                        name="partner.geos"
                        selectedOptions={selectedOptions}
                        onChange={
                          ({ detail }) => {
                            setFieldValue(
                              "partner.geos",
                              detail.selectedOptions
                                .map((el) => el.value)
                                .join(","),
                              true
                            )
                            setSelectedOptions(
                              detail.selectedOptions as OptionDefinition[]
                            )
                          }
                          //
                        }
                        options={[
                          { label: "AMER", value: "AMER" },
                          { label: "APAC", value: "APAC" },
                          { label: "LATAM", value: "LATAM" },
                          { label: "EMEA", value: "EMEA" },
                          { label: "Global", value: "Global" },
                        ]}
                        selectedAriaLabel="Selected"
                      />
                    </FormField>
                  </SpaceBetween>
                </Container>
              </SpaceBetween>
            </Form>
          </form>
        )
      }}
    </Formik>
  )
}

export default FormContent
// export default {}
