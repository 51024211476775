import { useCollection } from "@amzn/awsui-collection-hooks"
import {
  Container,
  Grid,
  Header,
  Pagination,
  SpaceBetween,
  TextFilter,
} from "@amzn/awsui-components-react"
import Button from "@amzn/awsui-components-react/polaris/button"
import CollectionPreferences, {
  CollectionPreferencesProps,
} from "@amzn/awsui-components-react/polaris/collection-preferences"
import GTMCard from "components/Cards/GTMCard"
import { GTM } from "models/gtm"
import EmptyState from "pmsa-polaris/components/EmptyState"
import usePersistentState from "pmsa-polaris/hooks/usePersistentState"
import { getMatchesCountText, paginationLabels } from "pmsa-polaris/utils"
import { useEffect, useState } from "react"

const SEARCHABLE_COLUMNS = [
  "partnerName",
  "title",
  "benefits",
  "action",
  "maturity",
]

type Props = {
  dimension: string
  data: GTM[]
  error: any
  loading: boolean
}

const PAGE_SIZE_OPTIONS = [
  { value: 5, label: "5 Opportunitites" },
  { value: 10, label: "10 Opportunitites" },
  { value: 20, label: "20 Opportunitites" },
]

const GTMList: React.FC<Props> = ({ dimension, data, error, loading }) => {
  const [errorMessage, setErrorMessage] = useState("Failed to load GTMs")

  useEffect(() => {
    if (error && error.response?.data?.errorCode == "USER_NOT_FOUND") {
      error.response?.data?.message &&
        setErrorMessage(error.response.data.message)
    }
  }, [error])

  const [preferences, setPreferences] =
    usePersistentState<CollectionPreferencesProps.Preferences>(
      "pma.recommendationPreferences",
      {
        pageSize: 5,
        wrapLines: false,
      }
    )

  const clearFilter = () => {
    actions.setFiltering("")
  }

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(data || [], {
    filtering: {
      empty: (
        <EmptyState
          errorText={(error && errorMessage) || undefined}
          title="No GTM"
          subtitle="No go to market opportunities to display."
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          subtitle="We can’t find a match."
          action={<Button onClick={() => clearFilter()}>Clear filter</Button>}
        />
      ),
      filteringFunction: (item, filteringText) => {
        const filteringTextLowerCase = filteringText.toLowerCase()
        return SEARCHABLE_COLUMNS.map((key) => item[key as keyof GTM]).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().indexOf(filteringTextLowerCase) > -1
        )
      },
    },
    pagination: {
      pageSize: preferences.pageSize,
    },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: "lastModifiedDate",
        },
        isDescending: true,
      },
    },
    selection: {},
  })
  return (
    <SpaceBetween size="l">
      <Container
        disableContentPaddings={true}
        header={
          <SpaceBetween size="xs">
            <TableHeader itemCount={data?.length} />
            <Grid
              gridDefinition={[{ colspan: 8 }, { colspan: 3 }, { colspan: 1 }]}
            >
              <TextFilter
                {...filterProps}
                // countText={getMatchesCountText(filteredItemsCount!)}
                filteringAriaLabel="Filter GTM"
                filteringPlaceholder="Find GTM"
              />
              <Pagination {...paginationProps} ariaLabels={paginationLabels} />
              <CollectionPreferences
                title="Preferences"
                confirmLabel="Confirm"
                cancelLabel="Cancel"
                preferences={preferences}
                onConfirm={({ detail }) => setPreferences(detail)}
                pageSizePreference={{
                  title: "Page size",
                  options: PAGE_SIZE_OPTIONS,
                }}
              />
            </Grid>
            {filterProps.filteringText && (
              <span className="filtering-results">
                {getMatchesCountText(filteredItemsCount!)}
              </span>
            )}
          </SpaceBetween>
        }
      ></Container>

      {items!.map((item, i) => (
        <GTMCard gtm={item} dimension={dimension} key={i} />
      ))}
    </SpaceBetween>
  )
}

const TableHeader: React.FC<{
  itemCount?: number
  selectedItems?: Readonly<GTM[]>
}> = ({ itemCount, selectedItems }) => {
  return (
    <Header
      variant="h2"
      counter={
        (itemCount &&
          (selectedItems
            ? `(${selectedItems.length}/${itemCount})`
            : `(${itemCount})`)) ||
        ""
      }
    >
      GTMs
    </Header>
  )
}

export default GTMList
