import HelpPanel from "@amzn/awsui-components-react/polaris/help-panel"

const ToolsPanel = () => (
  <HelpPanel
    header={<h2>GTM</h2>}
    footer={
      <p>
        This page displays a list of all of your Go to market opportunitites for
        a partner.
      </p>
    }
  />
)

export default ToolsPanel
