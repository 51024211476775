import { useCollection } from "@amzn/awsui-collection-hooks"
import { ColumnLayout } from "@amzn/awsui-components-react"
import Button from "@amzn/awsui-components-react/polaris/button"
import { ButtonDropdownProps } from "@amzn/awsui-components-react/polaris/button-dropdown"
import CollectionPreferences, {
  CollectionPreferencesProps,
} from "@amzn/awsui-components-react/polaris/collection-preferences"
import Header from "@amzn/awsui-components-react/polaris/header"
import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces"
import Pagination from "@amzn/awsui-components-react/polaris/pagination"
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between"
import Table from "@amzn/awsui-components-react/polaris/table"
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter"
import opportunities from "api/partners"
import keyBy from "lodash/keyBy"
import { Partner } from "models/partner"
import EmptyState from "pmsa-polaris/components/EmptyState"
import NavigationButton from "pmsa-polaris/components/NavigationButton"
import usePersistentState from "pmsa-polaris/hooks/usePersistentState"
import usePromise from "pmsa-polaris/hooks/usePromise"
import useQueryString from "pmsa-polaris/hooks/useQueryString"
import { getMatchesCountText, paginationLabels } from "pmsa-polaris/utils"
import qs from "qs"
import React, { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import routes from "routes"

import { DEFAULT_COLUMN_IDS, SEARCHABLE_COLUMNS } from "./columnsNames"
import {
  COLUMN_DEFINITIONS,
  PAGE_SIZE_OPTIONS,
  VISIBLE_CONTENT_OPTIONS,
} from "./tableConfig"

// const { sfdcBaseUrl } = (window as any)["runConfig"]

const DEFAULT_STAGE = { label: "All Partners", value: "all" }

const STAGE_OPTIONS: OptionDefinition[] = [DEFAULT_STAGE]

const STAGES_BY_VALUE = keyBy(STAGE_OPTIONS, "value")

const PartnersTable = () => {
  const queryParams = useQueryString()
  const navigate = useNavigate()

  const { alias, stage: queryStage } = queryParams

  const stageOption = STAGES_BY_VALUE[queryStage] || DEFAULT_STAGE

  const [{ loading, error, data: partners }, getOpportunities] = usePromise(
    opportunities.getAll
  )

  useEffect(() => {
    getOpportunities(true, alias)
  }, [getOpportunities, alias])

  const [errorMessage, setErrorMessage] = useState("Failed to load partners")

  useEffect(() => {
    if (error && error.response?.data?.errorCode == "USER_NOT_FOUND") {
      error.response?.data?.message &&
        setErrorMessage(error.response.data.message)
    }
  }, [error])

  const [preferences, setPreferences] =
    usePersistentState<CollectionPreferencesProps.Preferences>(
      "pma.partnerTablePreferences",
      {
        pageSize: 10,
        visibleContent: DEFAULT_COLUMN_IDS,
        wrapLines: false,
      }
    )

  const stageChanged = useCallback(
    (option: OptionDefinition) => {
      navigate(`?${qs.stringify({ ...queryParams, stage: option.value })}`, {
        replace: true,
      })
    },
    [navigate, queryParams]
  )

  // const matchesStage = (item: Readonly<Partner>, stage: string) => {
  //   if (stage === "all") return true
  //   else return stage === item.stageName.toLowerCase()
  // }

  const clearFilter = () => {
    actions.setFiltering("")
    stageChanged(DEFAULT_STAGE)
  }

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(partners || [], {
    filtering: {
      empty: (
        <EmptyState
          errorText={(error && errorMessage) || undefined}
          title="No partners"
          subtitle="No partners to display."
          action={
            <NavigationButton href={routes.partnerCreate}>
              AddPartner
            </NavigationButton>
          }
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          subtitle="We can’t find a match."
          action={<Button onClick={() => clearFilter()}>Clear filter</Button>}
        />
      ),
      filteringFunction: (item, filteringText) => {
        const filteringTextLowerCase = filteringText.toLowerCase()
        return SEARCHABLE_COLUMNS.map((key) => item[key as keyof Partner]).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().indexOf(filteringTextLowerCase) > -1
        )
      },
    },
    pagination: {
      pageSize: preferences.pageSize,
    },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: "lastModifiedDate",
        },
        isDescending: true,
      },
    },
    selection: {},
  })

  const { selectedItems } = collectionProps
  return (
    <Table
      {...collectionProps}
      loading={loading}
      loadingText="Loading opportunities"
      // selectionType="multi"
      stickyHeader={true}
      resizableColumns={true}
      wrapLines={preferences.wrapLines}
      header={
        <TableHeader
          itemCount={partners?.length}
          selectedItems={selectedItems}
        />
      }
      visibleColumns={preferences.visibleContent}
      columnDefinitions={COLUMN_DEFINITIONS}
      items={items}
      pagination={
        <Pagination {...paginationProps} ariaLabels={paginationLabels} />
      }
      filter={
        <SpaceBetween size="l">
          <ColumnLayout columns={2}>
            <TextFilter
              {...filterProps}
              // countText={getMatchesCountText(filteredItemsCount!)}
              filteringAriaLabel="Filter opportunities"
              filteringPlaceholder="Find Partner"
            />
            {filterProps.filteringText && (
              <span className="filtering-results">
                {getMatchesCountText(filteredItemsCount!)}
              </span>
            )}
          </ColumnLayout>
        </SpaceBetween>
      }
      preferences={
        <CollectionPreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
          preferences={preferences}
          onConfirm={({ detail }) => setPreferences(detail)}
          pageSizePreference={{
            title: "Page size",
            options: PAGE_SIZE_OPTIONS,
          }}
          wrapLinesPreference={{
            label: "Wrap lines",
            description: "Check to see all the text and wrap the lines",
          }}
          visibleContentPreference={{
            title: "Select visible columns",
            options: VISIBLE_CONTENT_OPTIONS,
          }}
        />
      }
    />
  )
}

const TableHeader: React.FC<{
  itemCount?: number
  selectedItems?: Readonly<Partner[]>
}> = ({ itemCount, selectedItems }) => {
  const selectedItem = selectedItems && selectedItems[0]

  const isOnlyOneSelected = selectedItems?.length === 1

  const [{ loading, error }, updateOpportunity] = usePromise(
    opportunities.update
  )

  const extendOpportunity = (days: number) => {
    if (selectedItem) {
      updateOpportunity(selectedItem.id, {
        ...selectedItem,
      })
    }
  }

  const handleActionExtendClick = (
    evt: CustomEvent<ButtonDropdownProps.ItemClickDetails>
  ) => {
    if (evt.detail.id.startsWith("ext")) {
      extendOpportunity(parseInt(evt.detail.id.substring(3)))
    }
  }

  return (
    <Header
      variant="h2"
      counter={
        (itemCount &&
          (selectedItems
            ? `(${selectedItems.length}/${itemCount})`
            : `(${itemCount})`)) ||
        ""
      }
      actions={
        <SpaceBetween direction="horizontal" size="s">
          <NavigationButton variant="primary" href={routes.partnerCreate}>
            Add Partner
          </NavigationButton>
        </SpaceBetween>
      }
    >
      Partners
    </Header>
  )
}

export default PartnersTable
