import { TableProps } from "@amzn/awsui-components-react/polaris/table"
import { Capability } from "models/dimension"

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<Capability>[] = [
  {
    id: "capability",
    header: "Capability",
    cell: (item) => item.name,
    minWidth: "100px",
  },
  {
    id: "subdimension",
    header: "Subdimension",
    cell: (item) => item.subDimension,
    minWidth: "100px",
  },
  {
    id: "maturityScore",
    header: "Maturity Score",
    cell: (item) => item.maturityScore,
    minWidth: "100px",
  },
]
