import { useCollection } from "@amzn/awsui-collection-hooks"
import { Header, Table } from "@amzn/awsui-components-react"
import { Dimension } from "models/dimension"
import React from "react"

import { COLUMN_DEFINITIONS } from "./tableConfig"

type Props = {
  data: Dimension
  loading: boolean
  error: any
}

export const CapbilitiesTable: React.FC<Props> = ({ data, loading, error }) => {
  const { items, collectionProps } = useCollection(data.capabilities, {
    pagination: {
      pageSize: 10,
    },
    sorting: {},
    selection: {},
  })
  return (
    <Table
      {...collectionProps}
      loading={loading}
      loadingText="Loading dimensions"
      columnDefinitions={COLUMN_DEFINITIONS}
      items={items}
      header={<Header variant="h2">Capabilites</Header>}
      stickyHeader={true}
      resizableColumns={true}
    />
  )
}
