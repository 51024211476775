import { Container, Header, SpaceBetween } from "@amzn/awsui-components-react"
import TextContent from "@amzn/awsui-components-react/polaris/text-content"
import partners from "api/partners"
import { Partner } from "models/partner"
import Breadcrumbs from "pmsa-polaris/components/Breadcrumbs"
import MainLayout from "pmsa-polaris/components/MainLayout"
import NavigationButton from "pmsa-polaris/components/NavigationButton"
import usePromise from "pmsa-polaris/hooks/usePromise"
import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { routeParams } from "routes"

import DimensionsContainer from "./DimensionsContainer"
import PartnerDetailsColumn from "./PartnerDetailsColumn"
import ToolsPanel from "./tools-topics"

// const { sfdcBaseUrl } = (window as any)["runConfig"]

const DetailsPage: React.FC<{
  id: string
  partner?: Partner
  loading: boolean
  error: any
}> = ({ id, partner, loading, error }) => (
  <Container
    header={
      <Header
        variant="h2"
        actions={
          <SpaceBetween direction="horizontal" size="m">
            <NavigationButton
              variant="normal"
              href={routeParams.partnerEdit({ id })}
            >
              Edit
            </NavigationButton>
          </SpaceBetween>
        }
      >
        Partner Details
      </Header>
    }
  >
    <div
      style={{
        maxHeight: 130,
        height: 70,
        overflowY: "auto",
        overflowX: "clip",
      }}
    >
      <PartnerDetailsColumn
        partner={partner!}
        error={error}
        loading={loading}
      />
    </div>
  </Container>
)

const PartnerDetails = () => {
  const { id: idParam } = useParams()
  const id = idParam!

  const [{ loading, error, data: partner }, getPartner] = usePromise(
    partners.get
  )

  useEffect(() => {
    getPartner(id)
  }, [getPartner, id])

  return (
    <MainLayout
      breadcrumbs={
        <Breadcrumbs
          items={[
            { text: "Partners", href: "/" },
            { text: partner ? partner.partnerName : id, href: "" },
          ]}
        />
      }
      tools={<ToolsPanel />}
    >
      <SpaceBetween size="l">
        <TextContent>
          <h1>Partner Overview</h1>
        </TextContent>
        <DetailsPage
          id={id}
          error={error}
          partner={partner}
          loading={loading}
        />
        <DimensionsContainer id={id} partner={partner} />
      </SpaceBetween>
    </MainLayout>
  )
}

export default PartnerDetails
