import { Auth } from "aws-amplify"
import logo from "logo.png"
import { StandardTopNavigation } from "pmsa-polaris/components/StandardTopNavigation"
import { useEffect, useState } from "react"

const TopNavigationBar: React.FC = () => {
  const [user, setUser] = useState({
    name: "",
    email: "",
  })

  useEffect(() => {
    try {
      Auth.currentAuthenticatedUser({
        bypassCache: false,
      }).then((user) => {
        setUser({
          name: `${user.attributes.given_name} ${user.attributes.family_name}`,
          email: user.attributes.email,
        })
      })
    } catch (e) {
      //setError(e);
    }
  }, [])

  return (
    <StandardTopNavigation
      identity={{
        href: "/#",
        title: "Partner Maturity Assessment Tool",
        logo: {
          src: logo,
          alt: "PMA Tool",
        },
      }}
      utilities={[
        {
          type: "menu-dropdown",
          text: user.name,
          description: user.email,
          iconName: "user-profile",
          items: [
            {
              id: "support-group",
              text: "Links",
              items: [
                {
                  id: "workstreams",
                  text: "Workstreams Catalogue",
                  href: "https://w.amazon.com/bin/view/AWS/Teams/PartnerSA/Partner_Management/Workstreams/",
                  external: true,
                  externalIconAriaLabel: " (opens in new tab)",
                },
                {
                  id: "sfdc",
                  text: "AWS SFDC",
                  href: "https://aws-crm.my.salesforce.com/",
                  external: true,
                  externalIconAriaLabel: " (opens in new tab)",
                },
              ],
            },
          ],
        },
        {
          type: "button",
          iconName: "notification",
          badge: true,
        },
      ]}
    />
  )
}

export default TopNavigationBar
