import { BasePartner } from "./shared"

export const DIMENSIONS = ["Container", "Serverless", "Analytics"] as const

export type Dimension = BasePartner & {
  name: typeof DIMENSIONS[number]
  capabilities: Capability[]
}

export type Capability = {
  name: string
  subDimension: string
  maturityScore?: number
  description: string
}
