import Alert from "@amzn/awsui-components-react/polaris/alert"
import Box from "@amzn/awsui-components-react/polaris/box"
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout"
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between"
import Spinner from "@amzn/awsui-components-react/polaris/spinner"
import { Partner } from "models/partner"

type Props = {
  partner: Partner
  loading: boolean
  error: any
}

const PartnerDetailsColumn: React.FC<Props> = ({ partner, loading, error }) => {
  const isLoading = loading || false

  return (
    (isLoading && (
      <div className="fullscreen-center">
        <Spinner size="large" />
      </div>
    )) ||
    (partner && (
      <ColumnLayout columns={4} variant="text-grid">
        <SpaceBetween size="l">
          <div>
            <Box variant="awsui-key-label">Partner Name</Box>
            <div>{partner.partnerName}</div>
          </div>
        </SpaceBetween>

        <SpaceBetween size="l">
          <div>
            <Box variant="awsui-key-label">SPMS ID</Box>
            <div>{partner.spmsId}</div>
          </div>
        </SpaceBetween>
        <SpaceBetween size="l">
          <div>
            <Box variant="awsui-key-label">PDM</Box>
            <div>{partner.pdm}</div>
          </div>
        </SpaceBetween>
        <SpaceBetween size="l">
          <div>
            <Box variant="awsui-key-label">PMSA</Box>
            <div>{partner.pmsa}</div>
          </div>
        </SpaceBetween>
      </ColumnLayout>
    )) ||
    (error && (
      <Alert visible={true} type="error" header="Failed to fetch details">
        Could not get details due to an error
      </Alert>
    )) ||
    null
  )
}

export default PartnerDetailsColumn
