import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces"
import geosApi from "api/geos"
import { mapValues } from "lodash"
import { GeoRegions } from "models/workstream"
import usePromise from "pmsa-polaris/hooks/usePromise"
import React, { useContext, useEffect, useMemo } from "react"

type MyContext = {
  geosLoading: boolean
  regionOptionsByGeo?: Record<string, OptionDefinition[]>
  geoOptions?: OptionDefinition[]
  geoRegions?: GeoRegions
}

const getLookupDefaultValues = () => ({
  geos: {},
})

const DEFAULT_VALUE = {
  geosLoading: false,
}

const GeosCatalogContext = React.createContext<MyContext>(DEFAULT_VALUE)

const TRACKING_KEYS = {
  Geos: "geos",
} as const

type CatalogData<T> = {
  geos: T
}

type GeosItemLookup = CatalogData<Record<string, string>>

const MyContextProvider: React.FC = ({ children }) => {
  const [
    { loading: geosLoading, data: geosCatalog, error: geosError },
    getGeos,
  ] = usePromise(geosApi.get)

  useEffect(() => {
    getGeos()
  }, [getGeos])

  const geosData = useMemo(() => {
    let streamsById: GeosItemLookup | undefined = undefined
    let streamsByName: GeosItemLookup | undefined = undefined

    if (geosCatalog?.geoRegions) {
      streamsById = getLookupDefaultValues()
      streamsByName = getLookupDefaultValues()
    }

    const regionOptionsByGeo = mapValues(
      geosCatalog?.geoRegions || {},
      (regions) =>
        regions.map((region) => ({
          value: region,
        }))
    )

    const geoOptions: OptionDefinition[] = Object.keys(
      geosCatalog?.geoRegions || {}
    ).map((geo) => ({
      value: geo,
    }))

    return {
      streamsById,
      streamsByName,
      geoRegions: geosCatalog?.geoRegions,
      geoOptions,
      regionOptionsByGeo,
      geosLoading: geosLoading,
      geosCatalog: geosCatalog,
      geosError: geosError,
    }
  }, [geosCatalog, geosError, geosLoading])

  return (
    <GeosCatalogContext.Provider value={geosData}>
      {children}
    </GeosCatalogContext.Provider>
  )
}

export const useWorkstreamCatalog = () => useContext(GeosCatalogContext)

export default MyContextProvider
