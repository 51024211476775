import MainLayout from "pmsa-polaris/components/MainLayout"

import PartnersTable from "./PartnersTable"
import ToolsPanel from "./PartnersToolsPanel"

const Home: React.FC = () => {
  return (
    <MainLayout
      breadcrumbItems={[{ text: "Home", href: "" }]}
      contentType="table"
      tools={<ToolsPanel />}
    >
      <PartnersTable />
    </MainLayout>
  )
}

export default Home
