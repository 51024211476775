import {
  Box,
  Container,
  SpaceBetween,
  Spinner,
  Tabs,
} from "@amzn/awsui-components-react"
import Header from "@amzn/awsui-components-react/polaris/header"
import { Partner } from "models/partner"
import NavigationButton from "pmsa-polaris/components/NavigationButton"
import React, { useState } from "react"
import { useParams } from "react-router-dom"

import { CapbilitiesTable } from "./CapabilitiesTable"
import MaturityOverview from "./MaturityOverview"

type Props = {
  loading: boolean
  error: any
  data: any
}

const DimensionsTabs: React.FC<Props> = ({data: data, error, loading }) => {
  const { id: idParam } = useParams()
  const id = idParam!

  const [tabId,setTabId] = useState("Container")

  const tabs = [
    {
      label: "Container",
      id: "Container",
      content: (
        <Box>
          <SpaceBetween size="l">
            <MaturityOverview loading={loading} error={error} data={data[0]} />
            <CapbilitiesTable loading={loading} error={error} data={data[0]} />
          </SpaceBetween>
        </Box>
      ),
    },
    {
      label: "Serverless",
      id: "Serverless",
      content: (
        <Box>
          <SpaceBetween size="l">
            <MaturityOverview loading={loading} error={error} data={data[0]} />
            <CapbilitiesTable loading={loading} error={error} data={data[0]} />
          </SpaceBetween>
        </Box>
      ),
    },
    {
      label: "Analytics",
      id: "Analytics",
      content: (
        <Box>
          <SpaceBetween size="l">
            <MaturityOverview loading={loading} error={error} data={data[0]} />
            <CapbilitiesTable loading={loading} error={error} data={data[0]} />
          </SpaceBetween>
        </Box>
      ),
    },
  ]
  return (
    (loading && (
      <div className="fullscreen-center">
        <Spinner size="large" />
      </div>
    )) || (
      <Container
        header={
          <Header
            variant="h2"
            actions={
              <SpaceBetween direction="horizontal" size="m">
                <NavigationButton variant="primary" href={`/assessments/${id}/${tabId}/create`}>
                  New Assessment
                </NavigationButton>
              </SpaceBetween>
            }
          >
            Dimensions
          </Header>
        }
        disableContentPaddings
      >
        <Tabs onChange={(event)=>setTabId(event.detail.activeTabId)} activeTabId={tabId} variant="container" tabs={tabs} ariaLabel="Resource details" />
      </Container>
    )
  )
}

export default DimensionsTabs
