import HelpPanel from "@amzn/awsui-components-react/polaris/help-panel"

const ToolsPanel = () => (
  <HelpPanel
    header={<h2>Partner List</h2>}
    footer={<p>This page displays details of the partner.</p>}
  />
)

export default ToolsPanel
